import React from 'react';
import { Pencil, Eraser, Droplet, Trash2, Sticker } from 'lucide-react';

export default function Toolbar({ tool, setTool, onClearCanvas }) {
  return (
    <div className="flex justify-center space-x-4">
      <button
        onClick={() => setTool('pencil')}
        className={`p-2 rounded ${tool === 'pencil' ? 'bg-blue-200' : 'hover:bg-gray-200'}`}
        title="Pencil"
      >
        <Pencil size={24} />
      </button>
      <button
        onClick={() => setTool('eraser')}
        className={`p-2 rounded ${tool === 'eraser' ? 'bg-blue-200' : 'hover:bg-gray-200'}`}
        title="Eraser"
      >
        <Eraser size={24} />
      </button>
      <button
        onClick={() => setTool('fill')}
        className={`p-2 rounded ${tool === 'fill' ? 'bg-blue-200' : 'hover:bg-gray-200'}`}
        title="Fill"
      >
        <Droplet size={24} />
      </button>
      <button
        onClick={() => setTool('sticker')}
        className={`p-2 rounded ${tool === 'sticker' ? 'bg-blue-200' : 'hover:bg-gray-200'}`}
        title="Sticker"
      >
        <Sticker size={24} />
      </button>
      <button
        onClick={onClearCanvas}
        className="p-2 hover:bg-gray-200 rounded"
        title="Clear Canvas"
      >
        <Trash2 size={24} />
      </button>
    </div>
  );
}