export const fillArea = (canvas, point, color, scale, offset) => {
  const context = canvas.getContext('2d');
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

  // Adjust the point for scale and offset
  const adjustedPoint = {
    x: Math.floor((point.x - offset.x) / scale),
    y: Math.floor((point.y - offset.y) / scale),
  };

  const targetColor = getPixelColor(imageData, adjustedPoint.x, adjustedPoint.y);
  const fillColor = hexToRgba(color);

  if (!colorsMatch(targetColor, fillColor)) {
    floodFill(imageData, adjustedPoint.x, adjustedPoint.y, fillColor, targetColor);
    context.putImageData(imageData, 0, 0);
  }
};

const getPixelColor = (imageData, x, y) => {
  const index = (y * imageData.width + x) * 4;
  return [
    imageData.data[index],     // Red
    imageData.data[index + 1], // Green
    imageData.data[index + 2], // Blue
    imageData.data[index + 3], // Alpha
  ];
};

const floodFill = (imageData, x, y, fillColor, targetColor) => {
  const width = imageData.width;
  const height = imageData.height;
  const stack = [[x, y]];
  const visited = new Set();

  while (stack.length > 0) {
    const [x, y] = stack.pop();
    const key = `${x},${y}`;

    if (visited.has(key)) {
      continue;
    }
    visited.add(key);

    if (x < 0 || x >= width || y < 0 || y >= height) {
      continue;
    }

    const currentColor = getPixelColor(imageData, x, y);
    if (!colorsMatch(currentColor, targetColor) && !isTransparent(currentColor)) {
      continue;
    }

    setPixelColor(imageData, x, y, fillColor);

    stack.push([x + 1, y]);
    stack.push([x - 1, y]);
    stack.push([x, y + 1]);
    stack.push([x, y - 1]);
  }
};

const colorsMatch = (color1, color2) => {
  return (
    color1[0] === color2[0] &&
    color1[1] === color2[1] &&
    color1[2] === color2[2] &&
    color1[3] === color2[3]
  );
};

const isTransparent = (color) => {
  return color[3] === 0;
};

const setPixelColor = (imageData, x, y, color) => {
  const index = (y * imageData.width + x) * 4;
  imageData.data[index] = color[0];     // Red
  imageData.data[index + 1] = color[1]; // Green
  imageData.data[index + 2] = color[2]; // Blue
  imageData.data[index + 3] = color[3]; // Alpha
};

const hexToRgba = (hex) => {
  let r = 0, g = 0, b = 0;

  // Remove the hash symbol if present
  hex = hex.replace(/^#/, '');

  if (hex.length === 3) {
    // Short notation, e.g., #0F0
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
  } else if (hex.length === 6) {
    // Full notation, e.g., #00FF00
    r = parseInt(hex.substr(0, 2), 16);
    g = parseInt(hex.substr(2, 2), 16);
    b = parseInt(hex.substr(4, 2), 16);
  }

  return [r, g, b, 255];
};
