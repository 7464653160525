import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback, useRef } from 'react';
import useCanvas from '../hooks/useCanvas';
import useDrawing from '../hooks/useDrawing';

const Canvas = forwardRef(({ color, tool, socket, selectedSticker}, ref) => {
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const lastPinchDistance = useRef(null);
  const [isStateLoaded, setIsStateLoaded] = useState(false);

  const {
    canvasRef,
    canvasSize,
    clearCanvas,
  } = useCanvas(socket, isStateLoaded);
  
  const { 
    startDrawing, 
    draw, 
    stopDrawing, 
    handleRemoteDraw,
    handleInitialState,
    handleRecentUpdates
  } = useDrawing(canvasRef, color, tool, socket, scale, offset, selectedSticker);

  useImperativeHandle(ref, () => ({
    clearCanvas
  }));

  const handleClearCanvas = useCallback(() => {
    clearCanvas();
  }, [clearCanvas]);

  useEffect(() => {
    console.log('socket', socket);
    if (socket) {
      socket.on('clearCanvas', clearCanvas);
      socket.on('drawBatch', handleRemoteDraw);
      socket.on('initialState', (data) => {
        console.log('initialState', data);
        handleInitialState(data);
        setIsStateLoaded(true);
      });
      socket.on('recentUpdates', handleRecentUpdates);
      return () => {
        socket.off('clearCanvas', clearCanvas);
        socket.off('drawBatch', handleRemoteDraw);
        socket.off('initialState');
        socket.off('recentUpdates', handleRecentUpdates);
      };
    }
  }, [socket, handleRemoteDraw, clearCanvas, handleInitialState, handleRecentUpdates, setIsStateLoaded]);


  const handleZoom = useCallback((delta, centerX, centerY) => {
    setScale(prevScale => {
      const newScale = prevScale + delta;
      if (newScale > 0.1 && newScale < 5) {
        // Adjust offset to zoom towards the center point
        setOffset(prevOffset => ({
          x: prevOffset.x - (centerX / prevScale - centerX / newScale),
          y: prevOffset.y - (centerY / prevScale - centerY / newScale)
        }));
        return newScale;
      }
      return prevScale;
    });
  }, []);

  const handlePan = useCallback((dx, dy) => {
    setOffset(prevOffset => ({
      x: prevOffset.x + dx / scale,
      y: prevOffset.y + dy / scale
    }));
  }, [scale]);

  const handleWheel = useCallback((event) => {
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();
    const centerX = event.clientX - rect.left;
    const centerY = event.clientY - rect.top;
    const delta = event.deltaY * -0.001;
    handleZoom(delta, centerX, centerY);
  }, [handleZoom]);

  const handleTouchStart = useCallback((event) => {
    if (event.touches.length === 2) {
      // Pinch-to-zoom start
      const distance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY
      );
      lastPinchDistance.current = distance;
    } else {
      startDrawing(event);
    }
  }, [startDrawing]);

  const handleTouchMove = useCallback((event) => {
    if (event.touches.length === 2) {
      // Pinch-to-zoom
      const distance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY
      );
      const delta = (distance - lastPinchDistance.current) * 0.01;
      lastPinchDistance.current = distance;

      const rect = event.currentTarget.getBoundingClientRect();
      const centerX = (event.touches[0].clientX + event.touches[1].clientX) / 2 - rect.left;
      const centerY = (event.touches[0].clientY + event.touches[1].clientY) / 2 - rect.top;
      
      handleZoom(delta, centerX, centerY);
    } else if (event.touches.length === 1) {
      // Single touch - draw or pan
      if (tool === 'pan') {
        const touch = event.touches[0];
        const prevTouch = event.currentTarget.lastTouch || touch;
        const dx = touch.clientX - prevTouch.clientX;
        const dy = touch.clientY - prevTouch.clientY;
        handlePan(dx, dy);
        event.currentTarget.lastTouch = touch;
      } else {
        draw(event);
      }
    }
  }, [draw, handlePan, handleZoom, tool]);

  const handleTouchEnd = useCallback((event) => {
    lastPinchDistance.current = null;
    event.currentTarget.lastTouch = null;
    stopDrawing(event);
  }, [stopDrawing]);

  const handleMouseDown = useCallback((event) => {
    if (event.button === 1 || tool === 'pan') { // Middle mouse button or pan tool
      event.preventDefault();
      const lastPosition = { x: event.clientX, y: event.clientY };
      
      const handleMouseMove = (moveEvent) => {
        const dx = moveEvent.clientX - lastPosition.x;
        const dy = moveEvent.clientY - lastPosition.y;
        handlePan(dx, dy);
        lastPosition.x = moveEvent.clientX;
        lastPosition.y = moveEvent.clientY;
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      startDrawing(event);
    }
  }, [handlePan, startDrawing, tool]);

  return (
    <div className="relative w-full h-full">
      <canvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        className="absolute top-0 left-0 w-full h-full touch-none"
        style={{
          transform: `scale(${scale}) translate(${offset.x}px, ${offset.y}px)`,
          transformOrigin: '0 0'
        }}
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      />
      {!isStateLoaded && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50">
          <p>Loading...</p>
        </div>
      )}
      {/* <div className="absolute bottom-4 right-4 flex space-x-2">
        <button onClick={() => handleZoom(0.1, canvasSize.width / 2, canvasSize.height / 2)} className="bg-blue-500 text-white px-2 py-1 rounded">+</button>
        <button onClick={() => handleZoom(-0.1, canvasSize.width / 2, canvasSize.height / 2)} className="bg-blue-500 text-white px-2 py-1 rounded">-</button>
        <button onClick={() => { setScale(1); setOffset({ x: 0, y: 0 }); }} className="bg-blue-500 text-white px-2 py-1 rounded">Reset</button>
      </div> */}
    </div>
  );
});

export default Canvas;