export const getScaledPoint = (canvas, event, zoom) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
  
    return {
      x: (event.clientX - rect.left) * scaleX / zoom,
      y: (event.clientY - rect.top) * scaleY / zoom
    };
  };
  
  export const drawPoint = (canvas, point, color, tool, zoom) => {
    const context = canvas.getContext('2d');
    const scaledRadius = tool === 'eraser' ? 10 / zoom : 2.5 / zoom;
  
    context.save(); // Save the current context state
  
    if (tool === 'eraser') {
      context.globalCompositeOperation = 'destination-out';
    }
  
    context.beginPath();
    context.arc(point.x * zoom, point.y * zoom, scaledRadius * zoom, 0, 2 * Math.PI);
    context.fillStyle = tool === 'eraser' ? 'rgba(0,0,0,1)' : color; // Color doesn't matter when erasing
    context.fill();
  
    context.restore(); // Restore the context to its original state
  };
  
  export const drawLineSegment = (canvas, start, end, color, tool, zoom) => {
    const context = canvas.getContext('2d');
    const scaledLineWidth = (tool === 'eraser' ? 20 : 5) / zoom;
  
    context.beginPath();
    context.moveTo(start.x * zoom, start.y * zoom);
    context.lineTo(end.x * zoom, end.y * zoom);
    context.strokeStyle = tool === 'eraser' ? '#FFFFFF' : color;
    context.lineWidth = scaledLineWidth * zoom;
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.stroke();
  
    // Draw an additional circle at the end point to fill potential gaps
    context.beginPath();
    context.arc(end.x * zoom, end.y * zoom, (context.lineWidth / 2), 0, 2 * Math.PI);
    context.fillStyle = context.strokeStyle;
    context.fill();
  };