import { useState, useCallback } from 'react';
import { getScaledPoint, drawPoint, drawLineSegment } from '../utils/drawingUtils';
import { fillArea } from '../utils/fillUtils';

export default function useDrawing(canvasRef, color, tool, socket, scale, offset, selectedSticker) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastPoint, setLastPoint] = useState(null);
  let updates = [];

  const getCoordinates = useCallback((event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clientX = event.clientX || (event.touches && event.touches[0].clientX);
    const clientY = event.clientY || (event.touches && event.touches[0].clientY);
    return {
      x: (clientX - rect.left),
      y: (clientY - rect.top)
    };
  }, [canvasRef]);

  const emitDrawEvent = useCallback((eventType, data) => {
    if(data.eventType === 'sticker') {
      socket.emit('drawBatch', [{ eventType, ...data }]);
    } else if (data.eventType === 'fill') {
      socket.emit('drawBatch', [{ eventType, ...data }]);
    } else {
      updates.push({ eventType, ...data });
    }
    if (updates.length >= 10) {
      if (socket && socket.connected) {
        socket.emit('drawBatch', updates);
      }
      updates = [];
    }
  }, [socket]);

  const placeSticker = useCallback((point) => {
    const canvas = canvasRef.current;
    if (canvas && selectedSticker) {
      const context = canvas.getContext('2d');
      context.save();
      context.scale(scale, scale);
      context.translate(offset.x, offset.y);
      context.font = '30px Arial';
      context.textBaseline = 'middle';
      context.textAlign = 'center';
      context.fillText(selectedSticker.emoji, point.x, point.y);
      context.restore();

      emitDrawEvent('sticker', { point, sticker: selectedSticker });
    }
  }, [canvasRef, selectedSticker, emitDrawEvent, scale, offset]);

  const startDrawing = useCallback((event) => {
    event.preventDefault();
    const point = getCoordinates(event);

    if (tool === 'sticker') {
      placeSticker(point);
    } else if (tool === 'fill') {
      fillArea(canvasRef.current, point, color, scale, offset);
      emitDrawEvent('fill', { point, color, scale, offset });
    } else {
      setIsDrawing(true);
      setLastPoint(point);
      drawPoint(canvasRef.current, point, color, tool, scale, offset);
      emitDrawEvent('point', { point, color, tool, scale, offset });
    }
  }, [canvasRef, color, tool, getCoordinates, emitDrawEvent, placeSticker, scale, offset]);

  const draw = useCallback((event) => {
    event.preventDefault();
    if (!isDrawing || tool === 'fill' || tool === 'sticker') return;

    const newPoint = getCoordinates(event);
    drawLineSegment(canvasRef.current, lastPoint, newPoint, color, tool, scale, offset);
    emitDrawEvent('line', { start: lastPoint, end: newPoint, color, tool, scale, offset });
    setLastPoint(newPoint);
  }, [canvasRef, color, tool, isDrawing, lastPoint, getCoordinates, emitDrawEvent, scale, offset]);

  const stopDrawing = useCallback(() => {
    setIsDrawing(false);
    setLastPoint(null);
  }, []);

  const handleRemoteDraw = useCallback(
    (data) => {
      data.forEach((event) => {
        const { eventType, point, start, end, color, tool, sticker, scale: remoteScale, offset: remoteOffset } = event;
        if (eventType === 'point') {
          drawPoint(canvasRef.current, point, color, tool, remoteScale, remoteOffset);
        } else if (eventType === 'line') {
          drawLineSegment(canvasRef.current, start, end, color, tool, remoteScale, remoteOffset);
        } else if (eventType === 'fill') {
          fillArea(canvasRef.current, point, color, remoteScale, remoteOffset);
        } else if (eventType === 'sticker') {
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');
          context.save();
          context.scale(remoteScale, remoteScale);
          context.translate(remoteOffset.x, remoteOffset.y);
          context.font = '30px Arial';
          context.textBaseline = 'middle';
          context.textAlign = 'center';
          context.fillText(sticker.emoji, point.x, point.y);
          context.restore();
        }
      });
    },
    [canvasRef]
  );

  // Implement handleInitialState
  const handleInitialState = useCallback(
    (data) => {
      if (data && data.length > 0) {
        handleRemoteDraw(data);
      }
    },
    [handleRemoteDraw]
  );

  // Implement handleRecentUpdates
  const handleRecentUpdates = useCallback(
    (data) => {
      if (data && data.length > 0) {
        handleRemoteDraw(data);
      }
    },
    [handleRemoteDraw]
  );

  return { startDrawing, draw, stopDrawing, handleRemoteDraw, handleInitialState, handleRecentUpdates };

}