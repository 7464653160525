import React, { useState, useEffect, useCallback, useRef } from 'react';
import { io } from 'socket.io-client';
import Canvas from './components/Canvas';
import Toolbar from './components/Toolbar';
import ColorPalette from './components/ColorPalette';
import StickerPalette from './components/StickerPalette';


export default function App() {
  const [color, setColor] = useState('#000000');
  const [tool, setTool] = useState('pencil');
  const [isConnected, setIsConnected] = useState(false);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const socketRef = useRef(null);
  const canvasRef = useRef(null);

  const BACKEND_URL = 'https://board.havania.app';
  const connectSocket = useCallback(() => {
    socketRef.current = io(BACKEND_URL, {
      path: '/api/socket.io',
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      autoConnect: true,
    });

    socketRef.current.on('connect', () => {
      console.log('Connected to server');
      setIsConnected(true);
    });

    socketRef.current.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
      setIsConnected(false);
    });

    socketRef.current.on('connect_error', (error) => {
      console.log('Connection error:', error);
      setIsConnected(false);
    });

    socketRef.current.connect();
  }, []);

  useEffect(() => {
    connectSocket();
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [connectSocket]);

  const handleClearCanvas = useCallback(() => {
    if (canvasRef.current && canvasRef.current.clearCanvas) {
      canvasRef.current.clearCanvas();
    }
    setTool('pencil');
  }, []);

  return (
    <div className="relative h-screen w-screen overflow-hidden flex">
      {/* Narrower color palette sidebar */}
      <div className="w-12 bg-gray-100 shadow-lg flex-shrink-0">
        <ColorPalette color={color} setColor={setColor} />
      </div>
      {/* Main content area */}
      <div className="flex-grow flex flex-col">
        {/* Canvas */}
        <div className="flex-grow relative">
          <Canvas 
            ref={canvasRef}
            color={color} 
            tool={tool} 
            socket={socketRef.current}
            selectedSticker={selectedSticker}
          />
        </div>
        {/* Bottom toolbar */}
        <div className="bg-blue-100 shadow-lg p-2">
          <Toolbar
            tool={tool}
            setTool={setTool}
            onClearCanvas={handleClearCanvas}
          />
          {tool === 'sticker' && (
            <div className="mt-2">
              <StickerPalette setSelectedSticker={setSelectedSticker} />
            </div>
          )}
          <div className="mt-2 text-sm text-blue-800 text-center">
            Status: {isConnected ? 'Connected' : 'Disconnected'}
          </div>
        </div>
      </div>
    </div>
  );
}