import React from 'react';

export default function ColorPalette({ color, setColor }) {
  const colors = [
    '#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF', '#FFFF00',
    '#FF00FF', '#00FFFF', '#FFA500', '#800080', '#008000', '#FFC0CB',
    '#A52A2A', '#808080', '#FFD700', '#40E0D0',
  ];

  return (
    <div className="h-full flex flex-col items-center overflow-y-auto py-2 space-y-1">
      {colors.map((c) => (
        <button
          key={c}
          className={`w-8 h-8 rounded-full ${color === c ? 'ring-2 ring-offset-1 ring-blue-500' : ''}`}
          style={{ 
            backgroundColor: c, 
            border: c === '#FFFFFF' ? '1px solid #000' : 'none'
          }}
          onClick={() => setColor(c)}
          aria-label={`Select ${c} color`}
        />
      ))}
    </div>
  );
}