import { useRef, useEffect, useState, useCallback } from 'react';

export default function useCanvas(socket, isStateLoaded) {
  const canvasRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const resizeCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas && canvas.parentElement) {
      const { clientWidth, clientHeight } = canvas.parentElement;
      canvas.width = clientWidth;
      canvas.height = clientHeight;
      setCanvasSize({ width: clientWidth, height: clientHeight });
      
      const context = canvas.getContext('2d');
      context.lineCap = 'round';
      context.lineJoin = 'round';
    }
  }, []);

  useEffect(() => {
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => window.removeEventListener('resize', resizeCanvas);
  }, [resizeCanvas]);


  const clearCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      if(!isStateLoaded) {
        socket.emit('getInitialState');
      }
    }
  }, [socket, isStateLoaded]);

  return { 
    canvasRef,
    canvasSize, 
    resizeCanvas,
    clearCanvas,
  };
}