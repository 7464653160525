import React from 'react';

const stickers = [
  { id: 'heart', emoji: '❤️' },
  { id: 'star', emoji: '⭐' },
  { id: 'flower', emoji: '🌸' },
  { id: 'rainbow', emoji: '🌈' },
  { id: 'unicorn', emoji: '🦄' },
  { id: 'butterfly', emoji: '🦋' },
];

export default function StickerPalette({ setSelectedSticker }) {
  return (
    <div className="flex flex-wrap justify-center p-2 bg-pink-100 rounded-lg shadow-md">
      {stickers.map((sticker) => (
        <button
          key={sticker.id}
          onClick={() => setSelectedSticker(sticker)}
          className="text-3xl m-1 hover:bg-pink-200 rounded p-1"
        >
          {sticker.emoji}
        </button>
      ))}
    </div>
  );
}